interface Client {
  id: string;
  hostnames: Array<string>;
  fallbackClient: boolean;
  openWareHost: string;
  openWareSecure: boolean;
  parseHost: string;
  parseAppId: string;
  feedbackURL: string | null;
  feedbackAssigned: string | null;
  logoImage: string | null;
  documentationURL: string | null;
  devHost: boolean | null;
}

const clients: Client[] = [
  {
    id: "kompetenzzentrum",
    hostnames: ["opendash.kompetenzzentrum-siegen.digital"],
    fallbackClient: false,
    openWareHost: "opendash.kompetenzzentrum-siegen.digital",
    openWareSecure: true,
    parseHost: "https://users.kompetenzzentrum-siegen.digital/parse",
    parseAppId: "1234567890",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/kpz.png"),
    documentationURL: null,
    devHost: false,
  },
  {
    id: "kompetenzzentrum_neu",
    hostnames: ["193.175.161.182"],
    fallbackClient: false,
    openWareHost: "193.175.161.182",
    openWareSecure: false,
    parseHost: "http://193.175.161.182/parse",
    parseAppId: "opendashfit",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/kpz.png"),
    documentationURL: null,
    devHost: false,
  },
  {
    id: "kuenne",
    hostnames: ["kuenne-dev.openinc.dev", "kuenne.openinc.dev"],
    fallbackClient: false,
    openWareHost: "kuenne-app.hosts.openinc.de",
    openWareSecure: true,
    parseHost: "https://kuenne-app.hosts.openinc.de/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/kuenne.png"),
    documentationURL: null,
    devHost: false,
  },

  {
    id: "braeuer",
    hostnames: ["braeuer.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/braeuer.png"),
    documentationURL: null,
    devHost: false,
  },
  {
    id: "miaas",
    hostnames: ["miaas.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/miaas.png"),
    documentationURL: null,
    devHost: false,
  },
  {
    id: "samsmart",
    hostnames: ["samsmart.hosts.openinc.de"],
    fallbackClient: false,
    openWareHost: "samsmart.hosts.openinc.de",
    openWareSecure: true,
    parseHost: "https://samsmart.hosts.openinc.de/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: null,
    documentationURL: null,
    devHost: false,
  },
  // {
  //   id: "localhost",
  //   hostnames: ["127.0.0.1"],
  //   fallbackClient: false,
  //   openWareHost: "openware.apps.openinc.dev",
  //   openWareSecure: true,
  //   parseHost: "http://localhost:9000/parse",
  //   parseAppId: "openinc",
  //   feedbackURL: null,
  //   feedbackAssigned: null,
  //   logoImage: null,
  //   documentationURL: null,
  //   devHost: false,
  // },
  {
    id: "demo",
    hostnames: ["localhost", "opendash.openinc.dev"],
    fallbackClient: true,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc-demo",
    feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=Test",
    feedbackAssigned: "94f2781b-eec5-4362-ba0c-c47a6822fc13", //Nico C. bekommt man durch die Website und Netzwerk untersuchen
    logoImage: null,
    documentationURL: null,
    devHost: true,
  },
  // {
  //   id: "localhost_demo",
  //   hostnames: ["127.0.0.1"],
  //   fallbackClient: true,
  //   openWareHost: "openware.apps.openinc.dev",
  //   openWareSecure: true,
  //   parseHost: "https://parse-demo.apps.openinc.dev/parse",
  //   parseAppId: "openinc-demo",
  //   feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=Test",
  //   feedbackAssigned: "94f2781b-eec5-4362-ba0c-c47a6822fc13", //Nico C. bekommt man durch die Website und Netzwerk untersuchen

  //   logoImage: null,
  //   documentationURL: null,
  //   devHost: false,
  // },
  {
    id: "localhost",
    hostnames: ["localhost", "127.0.0.1"],
    fallbackClient: false,
    openWareHost: "localhost:4567",
    openWareSecure: false,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc-demo",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: null,
    documentationURL: null,
    devHost: false,
  },
];

class ClientSelector {
  private client: Client;

  constructor() {
    //Check if the current hostname is a dev host
    //If so, use the client with the devHost flag

    if (!this.client) {
      //Find the fitting client for the current hostname
      this.client = clients.find((client) =>
        client.hostnames.includes(window.location.hostname)
      );
    }

    //If no client was found, use the fallback client
    if (!this.client) {
      this.client = clients.find((client) => client.fallbackClient);
    }
  }

  getOpenWareHost() {
    return this.client.openWareHost;
  }

  getOpenWareSecure() {
    return this.client.openWareSecure;
  }

  getParseHost = () => {
    return this.client.parseHost;
  };

  getParseAppId = () => {
    return this.client.parseAppId;
  };

  getFeedbackURL = () => {
    return this.client.feedbackURL;
  };

  getFeedbackAssigned = () => {
    return this.client.feedbackAssigned;
  };

  getLogoImage = () => {
    return this.client.logoImage;
  };

  getClientId = () => {
    return this.client.id;
  };

  getDocumentationURL = () => {
    return this.client.documentationURL;
  };

  getDevHost = () => {
    return this.client.devHost;
  };
}

const clientselector = new ClientSelector();
console.log("Connect to client:", clientselector.getClientId());

export default clientselector;
