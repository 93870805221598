import {
  Loading,
  SourceInterface,
  useOpenDashServices,
  useSource,
} from "@opendash/core";
import { Icon } from "@opendash/icons";
import { Map, MapboxMap, Marker } from "@opendash/plugin-geo-maplibre";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import { Description } from "@opendash/ui";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Layout,
  List,
  Row,
  Tooltip,
  Tree,
} from "antd";
import * as React from "react";

export default () => {
  const { SourceService } = useOpenDashServices();
  const sources = SourceService.getAll();
  const [collapsed, setCollapsed] = React.useState(true);
  const [currentSource, setCurrentSource] = useSource();
  const map = React.useRef<MapboxMap>(null);

  const mapSourceToTree = (
    source: SourceInterface,
    level: number,
    colorIndex: number
  ) => {
    const children = sources
      .filter((cSource) => cSource.parent === source.id)
      .map((cSource) => mapSourceToTree(cSource, level + 1, colorIndex));
    const start = //@ts-ignore
      Highcharts.getOptions().colors[
        //@ts-ignore
        colorIndex % Highcharts.getOptions().colors.length
      ];
    const color = start;
    // let color = cBlend(
    //   level / 5,
    //   start,
    //   //@ts-ignore
    //   "#eeeeee",
    //   true
    // );

    return {
      id: source.id,
      key: source.tag ? source.tag : source.id,
      label: source.name,
      title: source.name,
      meta: source.meta,
      level,
      color,
      icon: (
        <Avatar
          size={!collapsed ? 32 : 32 /*/ Math.pow(1.3, level)*/}
          style={{
            color: "var(--opendash-color-white)",
            backgroundColor: color,
          }}
        >
          {source.name
            .split(" ")
            .map((part) => part[0])
            .join("")}
        </Avatar>
      ),
      children: children.length > 0 ? children : null,
    };
  };

  const sourceTree = sources
    .filter((cSource) => !cSource.parent)
    .map((cSource) => mapSourceToTree(cSource, 0, 0));

  console.log(sourceTree);
  const config = {
    _history: {
      aggregation: false,
      live: true,
      limit: 1,
    },
    _sources: [],
    _items: [],
    _dimensions: currentSource.meta.kpiData || [],
    title: currentSource.name || "No Name",
    description: currentSource.meta.description || "no description",
  };

  const renderSourceItem = (item) => {
    return (
      <>
        <Tooltip title={item.label} placement="right">
          <List.Item
            style={{
              cursor: "pointer",
              borderRight:
                currentSource.id === item.id ? "4px solid " + item.color : null,
            }}
            onClick={() => {
              setCurrentSource(item.id);
            }}
          >
            <List.Item.Meta
              style={{ marginLeft: collapsed ? 20 : 10 + item.level * 20 }}
              avatar={item.icon}
              title={!collapsed ? item.label : ""}
              description={!collapsed ? item.key : ""}
            />
          </List.Item>
        </Tooltip>
        {item.children &&
          item.children.map((child) => {
            return renderSourceItem(child);
          })}
      </>
    );
  };
  if (!sources || sources.length == 0) {
    return <Loading message="Loading..." />;
  }
  React.useEffect(() => {
    setTimeout(() => {
      map.current.resize();
    }, 500);
  }, []);

  return (
    <Layout>
      <Layout.Sider
        width="20%"
        collapsible
        collapsed={false}
        //onCollapse={(value) => setCollapsed(value)}
        theme="light"
      >
        <Tree
          treeData={sourceTree}
          onSelect={(titles, e) => {
            setCurrentSource(e.node.id);
          }}
        ></Tree>
      </Layout.Sider>
      <Layout.Content>
        <Row>
          <Col span={24}>
            <div>
              <Map
                width={"100%"}
                height={400}
                mapRef={map}
                initialLongitude={
                  currentSource.meta.location?.longitude || 10.447683
                }
                initialLatitude={
                  currentSource.meta.location?.latitude || 51.163375
                }
                initialZoom={12}
              >
                <Marker
                  longitude={
                    currentSource.meta.location?.longitude || 10.447683
                  }
                  latitude={currentSource.meta.location?.latitude || 51.163375}
                />
              </Map>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={8} style={{ padding: "10px" }}>
            <div style={{ paddingTop: "10px" }}>
              <Description>Overview</Description>
              {config._dimensions.length === 0 && (
                <>
                  <Empty></Empty>
                </>
              )}
              {config._dimensions.length > 0 && (
                <div style={{ marginBottom: "10px" }}>
                  <WidgetStatic
                    // key={"state.visualisation" + "~" + "asd"}
                    config={config}
                    type="opendash-widget-hc-multi-kpi"
                  />
                </div>
              )}
              <Button
                type="primary"
                ghost
                style={{ marginLeft: "10px", width: "calc(100% - 20px)" }}
                icon={<Icon icon="fa:plus" />}
              ></Button>
            </div>
          </Col>
          <Col span={16}></Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};
